import React from "react"
import PropTypes from "prop-types"

import Box from "./box"
import Stack from "./stack"
import Heading from "./heading"
import Paragraph from "./paragraph"

const InfoBox = ({ children, title, titleElement }) => {
  /* random number between 1 and 4 */
  const randomNumber = Math.floor(Math.random() * (4 - 1 + 1) + 1)

  /* angles for rotated box */
  const angles = {
    1: "-2deg",
    2: "-1deg",
    3: "1deg",
    4: "2deg",
  }

  const randomAngle = angles[randomNumber]

  return (
    <Box
      sx={{
        borderTopWidth: 4,
        borderLeftWidth: 4,
        borderStyle: "double",
        borderColor: "border",
        position: "relative",
        py: [6, 6, 12],
        pl: [6, 6, 12],
      }}
    >
      <Box
        sx={{
          color: "primary",
          position: "absolute",
          width: [6, 6, 8],
          left: [-3, -3, -4],
          top: [6, 6, 12],
          transform: `rotate(${randomAngle})`,
        }}
      >
        <svg viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0V14L11 7L0 0Z" fill="currentColor" />
        </svg>
      </Box>
      <Stack space={6}>
        <Box
          sx={{
            bg: "contrast",
            py: 2,
            px: 3,
            display: "inline-block",
            transform: `rotate(${randomAngle})`,
          }}
        >
          <Heading as={titleElement} level={4} color="background">
            {title}
          </Heading>
        </Box>
        <Paragraph>{children}</Paragraph>
      </Stack>
    </Box>
  )
}

InfoBox.propTypes = {
  titleElement: PropTypes.string,
  title: PropTypes.string,
}

InfoBox.defaultProps = {
  titleElement: "h2",
  title: "Um was geht es?",
}

export default InfoBox
